import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Divider, FormControl, Grid, InputLabel, MenuItem, ListSubheader, Paper, Select, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import AcePay from '../../../assets/images/acepay.png';
import actions from '../../../redux/main/quiz/actions';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';


const Payment = () => {
    const [plan, setPlan] = useState({plan: 'Silver', period: 'Yearly', value: 'YSilver'});
    const [transactionId, setTransactionId] = useState();
    const [holderName, setHolderName] = useState();
    const [bankName, setBankName] = useState();
    const handlePlan = (e) => {
        setPlan({plan: e.target.value.slice(1, e.target.value.length), period: e.target.value.substring(0,1), value: e.target.value});
    }
    const dispatch = useDispatch();
    const handleSubmit = () => {
        dispatch({ type: actions.CREATE_SUBSCRIPTION_DETAILS_START, planDetails: {plan: plan, holderName: holderName, bankName: bankName, transactionId: transactionId}});
    }
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 15, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Payment</Typography>
                <Typography variant='h6' style={{fontSize: '14px', textAlign: 'center', color: '#182230'}}>Trusted by 10,000+ Customers</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#1A56DB'}}>Learn</span> . <span style={{color: '#1A56DB'}}>Analyse</span> . <span style={{color: '#1A56DB'}}>Execute</span> . <span style={{color: '#1A56DB'}}>Make Fortune</span></Typography>
            </div>
            <Typography variant='h5' style={{fontsize: '20px', margin: '0.5em', color: '#00234B', textAlign: 'center'}}>Payment Methods</Typography>
            <div style={{margin: 20, marginTop: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper className={classes.cardSize}>
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>UPI QR</Typography>
                            <img src={AcePay} style={{maxWidth: '320px'}}></img>
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>UPI Id - acematrix@ybl | Name - K MOHAN</Typography>
                            <Divider />
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Bank Account Details</Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Account Number</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Holder Name</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Bank Name</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>IFSC Code</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Account Type</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>50200090703193</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>K MOHAN</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>HDFC BANK</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>HDFC0007630</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>CURRENT</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper className={classes.cardSize}>
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Payment Proof</Typography>
                            <FormControl className={classes.size}>
                                <InputLabel id='subscribe'>Subscription</InputLabel>
                                <Select
                                    id='subscribe'
                                    value={plan.value}
                                    label='Subscription'
                                    onChange={(e) => handlePlan(e)}
                                >
                                    <ListSubheader>Monthly</ListSubheader>
                                    <MenuItem value={'MSilver'}>Silver - ₹999</MenuItem>
                                    <MenuItem value={'MGold'}>Gold - ₹1,999</MenuItem>
                                    <MenuItem value={'MPlatinum'}>Platinum - ₹3,999</MenuItem>
                                    <ListSubheader>Yearly</ListSubheader>
                                    <MenuItem value={'YSilver'}>Silver - ₹9,990</MenuItem>
                                    <MenuItem value={'YGold'}>Gold - ₹19,990</MenuItem>
                                    <MenuItem value={'YPlatinum'}>Platinum - ₹39,990</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label='Transaction Id'
                                placeholder='Enter Transaction Id'
                                fullWidth
                                required
                                className={classes.margin10}
                                onChange={(e) => setTransactionId(e.target.value)}
                            />
                            <TextField
                                label='Bank Account Holder Name'
                                placeholder='Enter Bank Account Holder Name'
                                fullWidth
                                required
                                className={classes.margin10}
                                onChange={(e) => setHolderName(e.target.value)}
                            />
                            <TextField
                                label='Bank Name'
                                placeholder='Enter Bank Name'
                                fullWidth
                                required
                                className={classes.margin10}
                                onChange={(e) => setBankName(e.target.value)}
                            />
                            <Button
                                type='submit'
                                color='primary'
                                variant='contained'
                                fullWidth
                                className={classes.postBtn}
                                onClick={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
}

export default Payment;

const customStyles = makeStyles({
    size: {
        width: '100%',
        margin: '10px 0',
    },
    loginRoot: {
        flexGrow: 1,
        height: '100vh',
        backgroundColor: '#F7F7F7',
    },
    gridSize: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
    },
    cardSize: {
        padding: 20,
        margin: 'auto',
        borderRadius: 15,
        border: '1px solid rgb(234, 231, 239)',
        boxShadow: 'none',
    },
    textDecoration: {
        color: '#00B050',
        textDecoration: 'none'
    },
    margin10: {
        margin: '10px 0',
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '10px 0px',
        boxShadow: 'none',
        borderRadius: 10,
        color: '#FFF',
        backgroundColor: '#1A56DB',

        '&:hover': {
            color: '#1A56DB',
            boxShadow: 'none',
            backgroundColor: '#E8EFFC',
        },
    },
})
