import React from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {faq} from '../../helpers/constant';
import {theme} from '../../helpers/constant';

const FAQ = () => {
  const classes = customStyles();
  return (
    <div style={{padding: 20}}>
      <div style={{paddingBottom: 20}}>
        <Typography variant='h4' style={{color: `${theme.secondaryColor}`, paddingLeft: '1.5rem', textAlign: 'center', fontSize: '28px', fontWeight: 600}}>Frequently Asked Questions</Typography>
        <Typography variant='h6' style={{color: '#000', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>Chase the vision not money</Typography>
      </div>
      {faq.map((data) => (
      <Accordion className={classes.accordionCss}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography component="span">{data.query}</Typography>
        </AccordionSummary>
        <AccordionDetails>{data.answer}</AccordionDetails>
      </Accordion>
      ))}
    </div>
  );
}

export default FAQ;

const customStyles = makeStyles({
    accordionCss: {
        backgroundColor: '#FBFBFB',
        borderRadius: 12,
        boxShadow: 'none',
        marginBottom: 10,

        '&::before': {
          backgroundColor: '#FFF',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        },
    },
})
