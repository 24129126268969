import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Typography, Tabs, Tab, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import actions from '../../../redux/main/subscribe/actions';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';

const Question = ({question, selectedOption, onOptionChange, onSubmit}) => {
    console.log()
    return(
        <Card style={{padding: 20, boxShadow: 'none', border: '1px solid rgb(234, 231, 239)', borderRadius: 15}}>
            <Typography variant='h5' style={{textAlign: 'center', paddingBottom: 10}}>Question {question.id}</Typography>
            <Typography variant='h6' style={{paddingBottom: 10}}>{question.question}</Typography>
            <FormControl style={{paddingBottom: 10}}>
                <RadioGroup name="quiz-ans-options">
                {question.options.map((option, index) => (
                    <FormControlLabel value={option} control={<Radio />} label={option} key={index} checked={selectedOption === option} onChange={onOptionChange} />
                ))}
                </RadioGroup>
            </FormControl>
            <br />
            <Button variant='contained' onClick={onSubmit}>Submit</Button>
            {/* <form onSubmit={onSubmit} className="mt-2 mb-2">
                <Options
                    options={question.options}
                    selectedOption={selectedOption}
                    onOptionChange={onOptionChange}
                />
                <button type="submit" className="btn btn-primary mt-2">
                    SUBMIT
                </button>
            </form> */}
            
        </Card>
    )
}

const Options = ({options, selectedOption, onOptionChange}) => {
    return (
        <div className='options'>
            {options.map((option, index) => (
                <div key={index} className="form-check">
                    <input
                        type="radio"
                        name="option"
                        value={option}
                        checked={selectedOption === option}
                        onChange={onOptionChange}
                        className="form-check-input"
                    />
                    <label className="form-check-label">{option}</label>
                </div>
            ))}
        </div>
    );
}

const Score = ({score, onNextQuestion}) => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography variant='h4'>Results</Typography>
            <Typography variant='h5'>Your Score: {score}</Typography>
        </div>
    );
}

// QuestionBank.js

const qBank = [
    {
        id: 1,
        question: "What is the capital of Haryana?",
        options: ["Yamunanagar", "Panipat", "Gurgaon", "Chandigarh"],
        answer: "Chandigarh",
    },
    {
        id: 2,
        question: "What is the capital of Punjab?",
        options: ["Patiala", "Ludhiana", "Amritsar", "Chandigarh"],
        answer: "Chandigarh",
    },
    {
        id: 3,
        question: "What is the capital of India?",
        options: ["Delhi", "Mumbai", "Kolkata", "Chennai"],
        answer: "Delhi"
    },
    {
        id: 4,
        question: "What is the capital of Uttarakhad?",
        options: ["Roorkee", "Haridwar", "Dehradun", "Nanital"],
        answer: "Dehradun"
    },
    {
        id: 5,
        question: "What is capital of Uttar Pradesh?",
        options: ["GB Nagar", "Lucknow", "Prayagraj", "Agra"],
        answer: "Lucknow"
    },
]

const Quiz = () => {
    // const {quiz} = useSelector(state => state.quizReducer);
    const [qnsBank, setQnsBank] = useState(qBank);
    const [currentQns, setCurrentQns] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [score, setScore] = useState(0);
    const [quizEnd, setQuizEnd] = useState(false);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     loadTasks();
    // }, []);
    // const loadTasks = () => {
    //     dispatch({
    //         type: actions.GET_QUIZ_START,
    //     });
    // }

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        checkAnswer();
        handleNextQuestion();
    };

    const checkAnswer = () => {
        if (selectedOption === qnsBank[currentQns].answer) {
            setScore(score+1);
        }
    };

    const handleNextQuestion = () => {
        if (currentQns + 1 < qnsBank.length) {
            setCurrentQns(currentQns+1);
            setSelectedOption(selectedOption+1);
        } else {
            setQuizEnd(true)
        }
    };
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 15, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Quiz</Typography>
                <Typography variant='h6' style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>We make stock market simple and inspiring</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#1A56DB'}}>Learn</span> . <span style={{color: '#1A56DB'}}>Analyse</span> . <span style={{color: '#1A56DB'}}>Execute</span> . <span style={{color: '#1A56DB'}}>Make Fortune</span></Typography>
            </div>
            <Grid container spacing={2}>  
                <Grid item sm={12} md={4}></Grid>  
                <Grid item sm={12} md={4}>             
                    {/* <div className="App d-flex flex-column align-items-center justify-content-center"> */}
                        {/* <Typography variant='h4' style={{textAlign: 'center'}}>QUIZ APP</Typography> */}
                        {!quizEnd ? (
                            <Question
                                question={qnsBank[currentQns]}
                                selectedOption={selectedOption}
                                onOptionChange={e => handleOptionChange(e)}
                                onSubmit={e => handleFormSubmit(e)}
                            />
                        ) : (
                            <Score
                                score={score}
                                onNextQuestion={() => handleNextQuestion()}
                                className="score"
                            />
                        )}
                    {/* </div> */}
                </Grid>
                <Grid item sm={12} md={4}></Grid> 
            </Grid>
            <Footer />
        </>
    )
}

export default Quiz;

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: '40px',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
