import {StyledEngineProvider, ThemeProvider, CssBaseline} from '@mui/material';
import theme from './theme.js';
import AppRouter from './routes/index.js';

import './App.css';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
