import React, {useEffect, useState} from 'react'
import {Card, CardMedia, CardContent, CardActions, Collapse, Checkbox, Divider, Grid, Typography, Button} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import actions from '../../redux/main/webinars/actions';
import {makeStyles} from '@mui/styles';
import {theme} from '../../helpers/constant';

import Webinar1 from '../../assets/images/webinar-1.png';

const Webinars = () => {
    const [subscribeOpen, setSubscribeOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false);

    const dispatch = useDispatch();  
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_WEBINARS_START,
        });
    };
    const classes = customStyles();
    return(
        <div style={{padding: 20}}>
            <div style={{paddingBottom: 20}}>
                <Typography variant='h4' style={{color: `${theme.secondaryColor}`, textAlign: 'center', fontSize: '28px', fontWeight: 600}}>Upcoming Webinars</Typography>
                <Typography variant='h6' style={{color: '#000', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>Gain knowledge with our webinars</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3} key={1}>
                    {/* <Card style={{minWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 15, boxShadow: 'none'}}>
                        <CardMedia
                            sx={{height: 175}}
                            image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                            title='Demo Course Title'
                        />
                        <CardContent>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 5}}>
                                Live Webinar
                            </Typography>
                            <Typography variant='h5' style={{color: `${theme.secondaryColor}`, fontWeight: 600, marginBottom: 5}}>
                                How to Retire Early?
                            </Typography>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 5}}>
                                Speaker: <span style={{fontWeight: 600}}>Mr. Mohan Kandhaiya</span>
                            </Typography>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 8}}>
                                Duration: <span style={{fontWeight: 600}}>60 mins</span>
                            </Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: 600}}>02 Feb 2025</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{textAlign: 'right', fontSize: '18px', fontWeight: 600}}>11 AM (IST)</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Button variant='outlined' style={{color: '#1A56DB', border: '2px solid #1A56DB', padding: '3px 15px'}}>View Details</Button>
                                </Grid>
                                <Grid item xs={6} style={{justifyContent: 'right'}}>
                                    <Button variant='contained' className={classes.postBtn} href={'https://docs.google.com/forms/d/e/1FAIpQLSciYPvjjyYNToQGcHvFCPZoYy9BfhZOKrgglZzkWrjcHTFjCw/viewform?usp=sharing'}>Register Now</Button>
                                </Grid>
                            </Grid>                                    
                        </CardActions>
                    </Card> */}
                    <Typography variant='h6' style={{color: '#607285', fontSize: '16px', textAlign:'center', paddingBottom: 20}}>Currently No Upcoming Webinars</Typography>
                </Grid>
            </Grid>
            <div style={{paddingBottom: 20}}>
                <Typography variant='h4' style={{color: `${theme.secondaryColor}`, textAlign: 'center', fontSize: '28px', fontWeight: 600}}>Past Webinars</Typography>
                <Typography variant='h6' style={{color: '#000', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>Gain knowledge with our webinars</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3} key={1}>
                    <Card style={{minWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 15, boxShadow: 'none'}}>
                        <CardMedia
                            sx={{height: 175}}
                            image={Webinar1}
                            title='Demo Course Title'
                        />
                        <CardContent>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 5}}>
                                Live Webinar
                            </Typography>
                            <Typography variant='h5' style={{color: `${theme.secondaryColor}`, fontWeight: 600, marginBottom: 5}}>
                                How to Retire Early?
                            </Typography>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 5}}>
                                Speaker: <span style={{fontWeight: 600}}>Mr. Mohan K</span>
                            </Typography>
                            <Typography variant='h6' style={{fontSize: '16px', marginBottom: 8}}>
                                Duration: <span style={{fontWeight: 600}}>60 mins</span>
                            </Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: 600}}>02 Feb 2025</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{textAlign: 'right', fontSize: '18px', fontWeight: 600}}>11 AM (IST)</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Webinars;

const customStyles = makeStyles({
    postBtn: {
        minWidth: 100,
        padding: '5px 15px',
        boxShadow: 'none',
        borderRadius: 12,
        color: '#FFF',
        backgroundColor: '#1A56DB',

        '&:hover': {
            color: '#1A56DB',
            boxShadow: 'none',
            backgroundColor: '#E8EFFC',
        },
    },
})
