import {BrowserRouter, Routes, Route, Navigate} from 'react-router';

import Home from '../components/LandingPage';
import Discover from '../components/LandingPage/Discover';
import Products from '../components/LandingPage/Products';
import Subscription from '../components/LandingPage/Subscribe';
import Payment from '../components/LandingPage/Subscribe/Payment';
import About from '../components/LandingPage/About';
import PartnerProgram from '../components/LandingPage/Partner';
import BuyMeACoffee from '../components/LandingPage/BuyMeACoffee';

import Dashboard from '../components/UserPanel/Dashboard';
import Clutch from '../components/UserPanel/Clutch';
import Pursuit from '../components/UserPanel/Pursuit';
import Notifications from '../components/UserPanel/Notifications';
import Account from '../components/UserPanel/Account';

import UserLogin from '../components/UserLogin';
import UserRegister from '../components/UserRegister';
import Error404Page from '../components/Error404Page';
import FirstLine from '../components/UserPanel/FirstLine';
import Fortune from '../components/UserPanel/Fortune';
import ChangePassword from '../components/UserPanel/ChangePassword';
import UserSubscription from '../components/UserPanel/UserSubscription';
import Insurance from '../components/UserPanel/FirstLine/Insurance';
import Clan from '../components/UserPanel/Clan';
import CourseDetails from '../components/UserPanel/Pursuit/CourseDetails';
import PrivacyPolicy from '../components/LandingPage/PrivacyPolicy';
import Quiz from '../components/LandingPage/Quiz';

const PrivateRoute = ({children}) => {
    const sessionStorage = JSON.parse(window.sessionStorage.getItem('auth'));
    const auth = sessionStorage === null ? {isUserLoggedIn: false} : sessionStorage;
    return auth.isUserLoggedIn ? children : <Navigate to='/user/login' />;
};

const LoggedInRoute = ({children}) => {
    const sessionStorage = JSON.parse(window.sessionStorage.getItem('auth'));
    const auth = sessionStorage === null ? {isUserLoggedIn: false} : sessionStorage;
    return auth.isUserLoggedIn ? <Navigate to='/user/dashboard' /> : children;
};

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/*Landing Page*/}
                <Route exact path='/' element={<Home />} />
                <Route exact path='/discover' element={<Discover />} />
                <Route exact path='/products' element={<Products />} />
                <Route exact path='/subscribe' element={<Subscription />} />
                <Route exact path='/payment' element={<Payment />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/partner-program' element={<PartnerProgram />} />
                <Route exact path='/buy-me-a-coffee' element={<BuyMeACoffee />} />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route exact path='/terms-of-service' element={<Discover />} />

                <Route exact path='/quiz' element={<Quiz />} />
                
                {/*User Panel*/}
                <Route exact path='/user/login' element={<LoggedInRoute><UserLogin /></LoggedInRoute>} />
                <Route exact path='/user/signup' element={<LoggedInRoute><UserRegister /></LoggedInRoute>} />
                <Route exact path='/user/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route exact path='/user/clutch' element={<PrivateRoute><Clutch /></PrivateRoute>} />
                <Route exact path='/user/pursuit' element={<PrivateRoute><Pursuit /></PrivateRoute>} />
                <Route exact path='/user/pursuit/:courseId' element={ <PrivateRoute><CourseDetails /></PrivateRoute>} />
                <Route exact path='/user/firstline' element={<PrivateRoute><FirstLine /></PrivateRoute>} />
                <Route exact path='/user/firstline/insurance' element={<PrivateRoute><Insurance /></PrivateRoute>} />
                <Route exact path='/user/fortune' element={<PrivateRoute><Fortune /></PrivateRoute>} />
                <Route exact path='/user/clan' element={<PrivateRoute><Clan /></PrivateRoute>} />
                <Route exact path='/user/account' element={<PrivateRoute><Account /></PrivateRoute>} />
                <Route exact path='/user/account/changepassword' element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
                <Route exact path='/user/account/subscription' element={<PrivateRoute><UserSubscription /></PrivateRoute>} />
                <Route exact path='/user/account/notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
                
                {/*404 Error Page*/}
                {/* <Route path='*' element={<Navigate replace to='/404NotFound' />} /> */}
                <Route exact path='/404NotFound' element={<Error404Page />} />


            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;
