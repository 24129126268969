import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid,Link, Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import _ from 'lodash';
import clsx from 'clsx';
import actions from '../../../redux/main/scripts/actions';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';
import Pursuit from './Pursuit';
import Clutch from './Clutch';


const Discover = () => {
    const {scripts} = useSelector(state => state.scriptsReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_SCRIPTS_START,
        });
    }
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 30, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Market Discovery</Typography>
                <Typography variant='h6' style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>Track stock market indices and its companies</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#1A56DB'}}>Learn</span> . <span style={{color: '#1A56DB'}}>Analyse</span> . <span style={{color: '#1A56DB'}}>Execute</span> . <span style={{color: '#1A56DB'}}>Make Fortune</span></Typography>
            </div>
            {/* <Grid style={{padding: 10}}>
                <div>
                    <Typography variant='h4' style={{color: '#0c5aa5', paddingLeft: '1.5rem', textAlign: 'center', fontWeight: 600}}>Ideas for getting started</Typography>
                    <Typography variant='h6' style={{color: '#000', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>Chase the vision not money. The money will end up following you.</Typography>
                </div>
                <Grid container>
                    {scripts.map((data) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={data.companyName}>
                            <Paper style={{margin: '1.5rem', padding: '1rem', border: '1px solid rgb(234, 231, 239)', boxShadow: 'none', borderRadius: 20}}>
                                <Link to={`/company/${data.symbol.slice(4, data.symbol.length)}`} className={clsx(classes.margin8a, classes.textDecoration)}>
                                    <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px'}}>
                                        {data.companyName}
                                    </Typography>
                                </Link>
                                <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                    {data.symbol.slice(4, data.symbol.length)}
                                </Typography>
                                <hr />
                                <Grid container >
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant='subtitle' align='center' style={{color: '#7F90A2'}} className={classes.padding8}>
                                            Lot Size
                                        </Typography>
                                        <Typography variant='h6' className={clsx(classes.padding8, classes.h6Text)}>
                                            {data.lotSize}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant='subtitle' align='center' style={{color: '#7F90A2'}} className={classes.padding8}>
                                            CMP
                                        </Typography>
                                        <Typography variant='h6' className={clsx(classes.padding8, classes.h6Text)}>
                                            {data.cmp}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant='subtitle' align='center' style={{color: '#7F90A2'}} className={classes.padding8}>
                                            Today's High
                                        </Typography>
                                        <Typography variant='h6' className={clsx(classes.padding8, classes.h6Text)}>
                                            {data.high}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant='subtitle' align='center' style={{color: '#7F90A2'}} className={classes.padding8}>
                                            Today's Low
                                        </Typography>
                                        <Typography variant='h6' className={clsx(classes.padding8, classes.h6Text)}>
                                            {data.low}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} style={{padding: 10}}>
                                        <Typography variant='subtitle' align='center' style={{color: '#7F90A2'}} className={classes.padding8}>
                                            Max Intraday Profit
                                        </Typography>
                                        <Typography variant='h6' className={clsx(classes.padding8, classes.h6Text)} style={{color: '#009933'}}>
                                            {_.round((data.maxIntradayProfit), 2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid> */}
            <Clutch />
            <Pursuit />
            <Footer />
        </>
    )
}

export default Discover;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    padding8: {
        padding: 8,
    },
    postBtn: {
        minWidth: 120,
        margin: '10px 10px',
        padding: '8px 20px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#FFF',
        fontWeight: 600,
        backgroundColor: '#00B050',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
    textDecoration: {
        textDecoration: 'none'
    },
    h6Text: {
        fontSize: '16px',
        fontWeight: 600, 
    }
})

