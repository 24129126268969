import React from "react";

const PrivacyPolicy = () => {
    return (
        <div>Privacy Policy for Ace Matrix
        Consent
        By using our app and / or website, you hereby consent to our Privacy Policy and agree to its terms.
        
        Who are we
Ace Matrix, a Company registered under the Companies Act, 2013 and having its registered office in the state of KARNATAKA understands and respects the need and requirement to protect the privacy rights of its Users (“You”, “Your”). Ace Matrix shall be the controller of the personal data/information which may be collected from You or which may be processed, in relation to the Services/Platform.

What is this privacy policy for
This privacy policy is a statement that outlines how we collect, use, and manage the personal information of our users or customers. The purpose of our privacy policy is to provide transparency and clarity to individuals about how their personal information will be handled by us. It typically includes information on what types of personal information we will collect, how we will use it, who we will share it with (if anyone), and how we will protect it. Our privacy policy also outlines the rights of individuals in relation to their personal information, such as the right to access and correct their data. It is important for us to have a privacy policy in place to comply with relevant data protection laws and to build trust with our users or customers.

PERSONAL DATA WHICH IS COLLECTED
We collect and process the following data from You/Users including but not limited to:

Name, email, address, mobile number, gender, etc. at the time of registering with Our Website and/or while creating a User account or while filling questionnaires or forms or when You subscribe to Company’s updates by email and/or upload or submit any material or content while using the Services/platform and/or Ace Matrix website.
Log-in/User details and password details during the User account sign- in/registration/change password etc. processes.
Financial details in respect of the subscriptions availed on the platform/web
Your data on social media (say Facebook profile data) if You choose to log in through Facebook or other social media
All communications and emails and other correspondence between You and Us
The entire range of material or the content which You post on the Platform/Website.
Your IP address(es), br/owser and Operating System specifications including mobile br/owser and mobile operating systems.
HOW YOUR PERSONAL DATA MAY BE USED
We collect the data only for legitimate and lawful purposes for providing You access to the Website and for the usage of the Services through the Platform.

Your information shall be used to
Verification of identity at the time of signing into Your User account
Use for other purposes that We may disclose to You when We request your Information.
Contact You for queries posted on the Platform or contact You in any regard
Analyze, provide You with any statistical data on User account activity
We do not use any personally identifiable information for any other purpose other than for granting You access to Your User account or the Services and/or the Website. All Your payment (s) made through the partner payment gateway is/are safe and secure. If You choose to log in or sign up with Our Services through any social media like Facebook, etc. We request permission to access Your profile and seek appropriate permissions from You wherever required.​If You click on links on third party websites, You shall be governed by their terms of use and privacy policies and We, do not control the manner as to how and in what manner such third parties collect Your personal data and information and how they use it. We, do not endorse neither We, shall be responsible for any data breach or infringement of Your privacy rights or for loss or damage whatsoever if You visit, use or access such third party websites.​We may use Your contact information for marketing and promotional purposes about any prospective Service(s) of Ace Matrix, however, if You decide and write to Us that We should refrain from using Your data/information in respect of any marketing or promotional purposes, We will honour Your decision. If You do not write to Us to let Us know of any objections to the use of the data/information, it shall signify that You have accorded us permission for such use of the data/information under law.​Whichever way We use and process, please note that Your personal information shared by You is safe and secure and We use and process any information under the applicable laws and on Your consent for such processing for lawful purposes and for providing You the Service(s) and access to the Platform/Ace Matrix Website. Your creation of the User account and log in every time You use Our Services and/or the Platform amounts to such valid consent

DISCLOSURE OF YOUR INFORMATION
We may share information with a parent company, any subsidiaries, joint ventures, or other companies under a common control (collectively, “Affiliates”), provided that We require our Affiliates to honor this Privacy Policy.In the event of a corporate sale, merger, reorganization, dissolution of Ace Matrix or similar event, Your personal data may be part of the transferred assets.
We may also share Your personal data with our Related Companies for purposes consistent with this Privacy Policy.We may share Your information with third parties to whom You ask Us to send Your information.
‍
Ace Matrix may hire agents, consultants, and related third parties to perform certain business-related functions, say, mailing information, maintaining databases and processing payments and We may share information with them. Please note that when We employ another entity to perform such functions, We, only provide them with the information that they need to perform their specific function.
‍
In Our commitment to the applicable laws, We may disclose personal information if required to do so by law or to respond to government authorities/agencies for verification of identity or investigation and in the event of a legal proceeding, court process or other legal process/process of the court of law where We would be bound to do so by/under the applicable laws in force; or where We are mandated to establish or protect Our legal rights, intellectual property; and if it is necessary to verify the identity, prevent, detect or take action against any illegal activity/’ies, whatsoever including but not limited to cyber accidents, suspected or apprehended fraud, or where there is a reasonable apprehension of any situation(s) involving potential/material threats to the physical safety of any person, property etc.

Ace Matrix may disclose Your personal data if required to do so by law or in the good faith belief that such action is necessary in urgent circumstances to protect the personal safety of users of the Website or the public or to protect against legal liability including to resolve disputes, investigate problems, or enforce Our policies/agreements.

​We may share information, including personal information, with Our third-party service providers (such as credit card processors, managed cloud hosting providers like Amazon Web Services) for the purpose of providing the requisite hardware, software, networking, storage, and other services which We use, to keep Our Website/Service functional to maintain a high quality user experience. Each third-party service provider’s use of Your personal information is dictated by their respective privacy policy. We do not and shall not permit;

Any such third party service providers to use the data/information shared with them for the purpose of marketing their services; or

The use of the data/information for any other purpose other than in connection with the services they provide to us.

We may use various marketing tools to create a better user experience for users visiting Our Website/Platform. These marketing tools permit Us to look at aggregated data such as scrolling patterns, clicks, and allow Us to run tests to determine which pages result in the best actions. However, Your personally identifiable information is not used by any of these tools.​

We may use the third-party service providers for tracking the user activity.​We may use remarketing tracking cookies and conversion pixels from vendors such as Google and Facebook to present special offers to You for Our products or services over the Google Content Network and via social networks.

Therefore, You, may see an advertisement for one of Our products or services as a result of visiting our Website. In addition, We, may use custom audiences based on e-mails and phone numbers of users. This allows Us to present special offers for our products and services to you via Facebook and Google. However, Your personally identifiable information is not used by any remarketing service other than to present You special offers from Us.​We may use third-party service providers for remarketing.
‍
Facebook Ads
‍
Google Adwords

Newsletters:
We use Mailmodo as Our e-mail service provider and, through Our email service provider Mailmodo, We collect contact information, distribute Our newsletter, and track actions You take that assist Us in measuring the performance of the Website and newsletters. Upon subscription, We may also track the pages You visit on the website through Mailmodo.

If You are a Campaign owner, information about the Services You take from Us is passed on to Mailmodo.​ Our newsletters may contain tracking pixels. This pixel is embedded in e-mails and allows Us to analyze the success of Our newsletters. Because of these tracking pixels, We may see if and when You open an e-mail, which links within the e-mail You click, and the pages You visit on Our Website. This behavior is not passed to third-parties.

All data submitted at the time of subscription to Our newsletter is stored on Mailmodo servers. You may remove subscription to Our newsletter at any time, by clicking on the unsubscribe button provided in each e-mail. We use services of third party service providers such as Stripe, PayPal, PayU, Cashfree, PayTM, Razorpay etc. for Our products and services.

At no time is your banking information passed to the Website from any of the following third-party providers. We receive only information used for payment fulfillment.​The payment gateway collects personal information such as Your name, e-mail address, zip code, and payment information. For details You may refer to the Privacy Policy of such payment gateway. Upon making a payment, You will receive e-mail confirmations to the e- mail address You provide during Your payment.

PAYMENT INFORMATION
We use 256 BIT bit level of SSL standard data protection practices and technologies, and Your data is therefore protected in the course of transmission. Your data security is important to Us, however as no method of transmission over the internet or any method of electronic storage is completely secure, We, cannot guarantee You absolute security of Your data. However, We, shall strive to use reasonable and commercial acceptable means and industry standards to protect Your data/information.

PERSONAL DATA TRANSFERS
Your data/information is processed on cloud Servers in India However, Your data/Information may be even stored, transferred or transmitted over, and used, in such locations outside of Your state, region, county, province, country or other governmental/state jurisdiction where the privacy laws may not be as stringent or protective as the privacy laws in Your jurisdiction. Your data/Information may be subject to the local laws of the jurisdictions within which it is being collected, stored, used and/or disclosed and may be accessed by courts, law enforcement agencies, regulatory agencies and/or statutory authorities in such jurisdictions. In all such cases in the foregoing, by using or accessing The Service/Website/Platform You hereby unconditionally give Your consent to the access, storage, transfer, transmission of Your data/ Information and the usage and disclosure of Your data/information in all respects whatsoever.

PERSONAL DATA RETENTION
We adhere to the applicable laws with respect to retention of your data/information and We retain Your data/information only till the User account is subsisting with Us and in the event the User account is terminated/disabled due to any reason whatsoever, We shall not be responsible or liable either to maintain and/or hold any personal data/information whatsoever provided by You To know more about the grounds of such termination of User account.
RIGHTS UNDER THE INDIAN INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OR INFORMATION) RULES, 2011If You are a user from India and subject to the laws of the republic of India, You have the right To Access, Review Or Correct Or Object To Collection, Processing, Portability And Erasure Of Data/InformationYou may send a request in writing to Us to

Allow access to Your data/information or for review of Your data or
Allow You to correct inaccuracies (excluding that which would be violation of the law)
or deficient data
Delete or erase any personal data/information and We shall comply with Your request.
Withdraw Your consent to the process in which Your data is collected and/or processed, in which event, You may not be able to use the
Platform/Website/Services.We do not and shall not undertake any responsibility for the authenticity or genuineness of the data/information provided/shared by You and neither Ace Matrix makes/provides any representation(s), warranty(ies), guarantee(s), covenant(s), admissions, estoppels(s), whatsoever that the data/information which You provide is correct, accurate and shall be suitable, whether in whole or in part, for the purpose(s) for which it is/was provided by You during the access and/or use of Ace Matrix’s Platform/Service(s).

OTHER TYPES OF DATA/INFORMATION
Non-Identifiable Data: When You use Our Services through the Website, We, receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify You. We may store such information, or such information may be included in databases owned and maintained by Our affiliates, agents, or service providers. FinGod Advertising Solutions Private Limited Website may use such information and pool it with other information to track, for example, the total number of visitors to our Website, the number of visitors to each page of Our Website, and the domain names of Our visitors’ Internet service providers. Please note that it is important to note that no personal data is available or used in this process.
Aggregated personal data: We may conduct research on Our visitor/customer demographics, interests and behavior based on the personal data and other information provided to Us. This research may be compiled and analyzed on an aggregate basis, and We may share this aggregate data with Our affiliates, agents and business partners. This aggregate information does not identify You personally. We may also disclose aggregated user statistics in order to describe Our Platform/Services to current and prospective business partners, and to other third parties for other lawful purposes.

PUBLICLY VISIBLE INFORMATION
If You create a User account Ace Matrix Website/Platform or leave a comment on Website/Platform, certain information like Your name, may be publicly visible.

COOKIES
Ace Matrix Website may use cookies to store visitors’ preferences; record user-specific information on what pages users access or visit; ensure that visitors are not repeatedly sent the same banner ads; or to customize Website/Platform content based on visitors’ browser type or other information that the visitor may send to Us. Ace Matrix may also share information about Your use of Our Website with Our social media, advertising and analytics partners.

RIGHTS RELATED TO YOUR PERSONAL DATA
Under laws in certain countries in which We operate, users and other visitors to Our Website from those countries have a right to access personal data about themselves, and to amend, correct or delete personal data that is inaccurate, incomplete or outdated. We will, upon request, provide You with confirmation regarding whether We are processing personal data about You, consistent with applicable laws. In addition, upon Your request, We will take reasonable steps to correct, amend, or delete Your personal data that is found to be inaccurate, incomplete or processed in a manner non-compliant with this Privacy Policy or applicable law, except where the burden or expense of providing access would be disproportionate to the risks to Your privacy, where the rights of persons other than You would be violated or where doing so is otherwise consistent with applicable laws. Unless prohibited by applicable law We, reserve the right to charge a reasonable fee to cover costs for providing copies of personal information that You request.
Please note that while any amendments, corrections or deletions will be reflected in active user databases (as updated with a reasonable period of time), We may retain all personal data for backups, archiving, prevention of fraud and abuse, analytics, and satisfaction of other legal obligations We reasonably believe applicable.
You authorize Us to use and disclose any new or modified information that You provide in accordance with this Privacy Policy, and You agree that We are under no obligation to delete or modify information that You have previously chosen to provide Us as to which You have not instructed Us to take such action. Please remember, that if We have already disclosed some of Your personal data to third parties, We cannot access that personal data any longer and cannot force the deletion or modification of any such information by the parties to whom We have made those disclosures.
We may retain your personal data to comply with applicable laws, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations, enforce Our contracts, and take other actions otherwise permitted by law.
Please contact us at support@acematrix.in to submit a request or obtain information regarding any of the above.
Opt-out: You may opt-out of future e-mail communications by following the unsubscribe links in our e-mails. You may also notify us at support@acematrix.in to be removed from Our mailing list.

SENSITIVE PERSONAL INFORMATION
Unless You choose to provide it to Us, We, do not collect sensitive personal information from You while You are using the Website. This includes your Aadhaar /social security number, information regarding race or ethnic origin, political opinions, religious beliefs, health information, criminal background, or trade union memberships. If You decide to submit such information to Us, it will be subject to this Privacy and spam Policy. Minor/Children’s Information
Ace Matrix does not knowingly collect any personally identifiable information from minors/children under the age of 16. If You are under the age of 16, please do not submit any personal data through the Website. We request parents and legal guardians to monitor their children’s internet usage and to help enforce Our Privacy Policy by instructing their children never to provide personal data on the Website without their permission. If a parent or guardian believes that Ace Matrix has personally identifiable information of a child under the age of 16 in its database, please contact us immediately at support@acematrix.in and We will use our best efforts to promptly remove such information from Our records 

SPAM POLICY
Ace Matrix strongly opposes any spam mailing. We will only send e-mail to users that have explicitly requested and given their express consent to receive an e-mail from Ace Matrix. Examples may include:

Opt-in subscribers to Ace Matrix Newsletter
Users that complete a contact form 
All communication will be related and relevant to what You have requested.

WEB LINKS
Ace Matrix’s Website may contain links to other websites. Please be aware that Ace Matrix is not responsible for the privacy practices or the content of such other sites. It is Your responsibility to be aware when You leave Our Website to read the privacy and spam policies of each and every website that collects personally identifiable information. This Privacy and Spam Policy applies solely to information collected by Ace Matrix only.

EMAIL COMMUNICATIONS
If You send us an email with questions or comments, We, may use your personally identifiable information to respond to Your questions or comments, and We may save Your questions or comments for future reference. For security reasons, We, do not recommend that You send non-public personal information, such as passwords, social security numbers, or bank account information, to Us by email. You may “opt out” of receiving future commercial email communications from us by clicking the “unsubscribe” link included at the bottom of most emails We send, or as provided below; provided, however, We reserve the right to send you transactional emails such as customer service communications. Regardless of any other provision in this Privacy Policy, We reserve the right to disclose any personally identifiable or non-personally identifiable information about You to: (a) fulfill a government request; (b) conform with the requirements of the law or legal process; (c) protect or defend Our legal rights or property, Our Website, or other users; or (d) protect, in an emergency, the health and safety of Our Users or the general public. This includes exchanging information with third parties and organizations in connection with credit risk reduction and fraud protection.

Changes to the Privacy Policy
We reserve the right to update and modify this Privacy Policy any time and from time to time without prior notice. We will update the effective date in the Privacy Policy. Please read and visit Our Privacy Policy every time for any changes, alterations, modifications etc. Changes to this Privacy Policy are effective when they are posted on this page of the Privacy Policy or made effective as per the changes. We request you to please review this Privacy Policy periodically, and especially before You provide any personal data. Your continued use of the Website after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
Your access to and use of the Website may also be subject to any separate agreements or terms and conditions You have signed or agreed to with Ace Matrix. Please refer to those agreements as needed.How Do I Contact Ace Matrix Support?
If You have any genuine questions or concerns in matters regarding this Privacy Policy, You may reach out to support@acematrix.in for assistance.
Grievances
You can contact us at support@acematrix.in in case of any grievances?
        </div>
    )
}

export default PrivacyPolicy;