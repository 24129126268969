import React from 'react'
import {Grid, Paper, Typography, Tabs, Tab} from '@mui/material';
import {makeStyles} from "@mui/styles";
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';

const About = () => {
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 15, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant="h5" style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>About ACE MATRIX</Typography>
                <Typography variant="h6" style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>We make stock market simple and inspiring</Typography>
                <Typography variant="h6" style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#1A56DB'}}>Learn</span> . <span style={{color: '#1A56DB'}}>Analyse</span> . <span style={{color: '#1A56DB'}}>Execute</span> . <span style={{color: '#1A56DB'}}>Make Fortune</span></Typography>
            </div>
            <Grid style={{padding: 16}}>                
                <Typography variant="h5" style={{color: "#1A56DB", padding: "1rem", textAlign: "center", fontWeight: 600}}>About Us</Typography>
                <Typography variant="h6" style={{color: "#182230", padding: "1rem", textAlign: "center", fontWeight: 500}}>India's future leaders should embark on a focused and passionate journey to enhance their standard of living and adapt to India's unpredictable economic changes. Securing employment abroad is not the only path to wealth - the strategies followed by India's millionaires and billionaires to capitalize on domestic opportunities can also lead to success. Our membership program can serve as a valuable guide to help you achieve these goals.</Typography>
            </Grid>
            <Footer />
        </>
    )
}

export default About;

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
