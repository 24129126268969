import {Link} from 'react-router';
import {Box, Grid, Typography} from '@mui/material';
import {footerLeftMenus, footerMidMenus} from '../../helpers/constant';


const Footer = () => {
    return(
        <>
            <Box component='footer' style={{padding: 10, margin: 15, borderRadius: 20, border: '1px solid rgb(234, 231, 239)'}}>
                <div>
                <Grid container>
                    <Grid item xs={12} md={4.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography variant='h5' style={{fontWeight: 600, color: '#182230', marginBottom: 10}}> acematrix </Typography>
                            <div>
                                <Typography style={{fontSize: '16px', padding: 0, color: '#182230'}}>India's future leaders should embark on a focused and passionate journey to enhance their standard of living and adapt to India's unpredictable economic changes. Securing employment abroad is not the only path to wealth - the strategies followed by India's millionaires and billionaires to capitalize on domestic opportunities can also lead to success. Our membership program can serve as a valuable guide to help you achieve these goals.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>{footerLeftMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerLeftMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#182230', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>{footerMidMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerMidMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#182230', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>Contact</Typography>
                            <div style={{paddingLeft: 10}}>
                                {/* <Typography variant='h5' style={{fontSize: '18px', padding: 5, fontWeight: 500}}>Ace Matrix</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Paramathi Velur</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Namakkal District - 637 207</Typography> */}
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Email: mohankandhaiya9@gmail.com</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5, fontWeight: 500}}>Cell: 96779 92240</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{padding: 10, margin: 10}}>
                <Typography
                    variant='h6'
                    align='center'
                    style={{fontWeight: 400, color: '#182230', fontSize: '16px'}}
                >
                    We are financial service company | Ace Matrix | acematrix.in | Privacy Policy | Terms & Conditions
                </Typography>
                <Typography
                    variant='subtitle1'
                    align='center'
                    style={{color: '#182230', fontSize: '16px'}}
                >
                    {'Copyright © '}
                    <Link href='https://tech.acematrix.in/' style={{textDecoration: 'none', color: '#1A56DB', fontSize: '15px', fontWeight: 600}}>
                        Kandhaiya Tech
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </div>
            </Box>
            <div style={{padding: 2}}></div>
        </>
    );
}

export default Footer;
